<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Sensores</h4>
            <div class="small text-muted">Adminsitración de todos los sensores asignados a los clientes</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="3">
          <b-row>
            <b-col lg="12" md="12">
              <Widget :configWidget="configWidget" reference="cantidad_clientes" :hasAccess="configWidget.elements.cantClientes" />              
            </b-col>

            <b-col lg="12" md="12">
              <Widget :configWidget="configWidget" reference="cantidad_sensores" :hasAccess="configWidget.elements.cantSensores" />
            </b-col>

            <b-col lg="12" md="12">
              <Widget :configWidget="configWidget" reference="cantidad_alertas_hoy" :hasAccess="configWidget.elements.cantAlertasHoy" />                                            
            </b-col>
          </b-row>
        </b-col>

        <b-col lg="6">
          <b-row>
            <b-col lg="12" md="12">
              <Widget :configWidget="configWidget" reference="listado_alertas_hoy" :hasAccess="configWidget.elements.listadoAlertaHoy" />              
            </b-col>
            <b-col lg="6" md="12">              
              <Widget :configWidget="configWidget" reference="estado_tanques_notificaciones" :hasAccess="configWidget.elements.estadoTanquesNotificaciones" />                                        
            </b-col>
            <b-col lg="6" md="12">        
              <Widget :configWidget="configWidget" reference="listado_errores_tanques" :hasAccess="configWidget.elements.listadoErroresTanques" />              
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3">
          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openAssociate()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Asignar Sensor</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Asigne un sensor a un cliente
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openListSensors()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Monitor Cliente</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Revise los sensores de cada cliente
              </p>
            </b-list-group-item>
          </b-list-group>  

          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCrudTanks()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Tanques</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de los tanques
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openConfigurations()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Configuraciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración de bases y nodos.
              </p>
            </b-list-group-item>
          </b-list-group>  
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.PERSONAL,
          elements: {
            cantSensores: true,
            cantClientes: true,
            cantAlertasHoy: true,
            listadoAlertaHoy: true,
            estadoTanquesNotificaciones: true,
            listadoErroresTanques: true,
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */
      this.configWidget.elements.cantSensores = Helper.hasAccessWidget(this.configWidget, 'cantidad_sensores')
      this.configWidget.elements.cantClientes = Helper.hasAccessWidget(this.configWidget, 'cantidad_clientes')
      this.configWidget.elements.cantAlertasHoy = Helper.hasAccessWidget(this.configWidget, 'cantidad_alertas_hoy')
      this.configWidget.elements.listadoAlertaHoy = Helper.hasAccessWidget(this.configWidget, 'listado_alertas_hoy')
      this.configWidget.elements.estadoTanquesNotificaciones = Helper.hasAccessWidget(this.configWidget, 'estado_tanques_notificaciones')
      this.configWidget.elements.listadoErroresTanques = Helper.hasAccessWidget(this.configWidget, 'listado_errores_tanques')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openAssociate() {
        this.$router.push({ name: 'SensorsStaffCrudSensorsAssociateTankCustomer' })
      },
      openCrudTanks() {
        this.$router.push({ name: 'SensorsStaffCrudTank' })
      },
      openListSensors() {
        this.$router.push({ name: 'SensorsStaffListSensorsTankCustomer' })
      },
      openConfigurations() {
        this.$router.push({ name: 'SensorsStaffConfigurations' })
      }
    }    
   
  }
</script>

<style>

</style>
